<template>
  <div class="microneedling">
    <Hero src="Microneedling.jpg" :heading-text="'nesto &amp; nesto'" :cta-text="'ZAKAŽITE TERMIN'" background-url="Microneedling.jpg"></Hero>
    <HeadlineCopy src1="roller.jpg" src2="laser.png" :heading-text="'PHI laser'" :heading-subtitle="'Tretmani koji se mogu izvoditi <br/> PhiLaserom su uklanjanje tetovaža, <br/> trajne šminke, hiperpigmentacija i <br/> karbon piling.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <SingleTextComponent :heading-text="'UKLANJANJE TETOVAŽA - NEKI TEKST'" background-url="microneedling-insurance.jpg" background-url-mobile="prvoklasna-usluga-mobile.jpg"></SingleTextComponent>
    <HeadlineCopy src1="nanoremoval.jpg" src2="logophi.png" :heading-text="'Nanoremoval'" :heading-subtitle="'NanoRemoval je tehnika koja deluje na površini, što znači da će biti manje oštećenja kože, manje iritacije i najbrži rezutat uz brzo zastarenje i bez rizika od nastanka ožiljka. NanoRemoval tehnika sa njenom kombinacijom proizvodima, počinje da deluje gotovo odmah nakon prvog prelaza preko površine stare tetovaže ili trajne šminke, pigment se kreće kroz slojeve kože ka površini formirajući blagu koru.'" :cta-text="'Zakažite termin'"></HeadlineCopy>
    <Footer></Footer> 
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import HeadlineCopy from '@/components/HeadlineCopy.vue'
import SingleTextComponent from '@/components/SingleTextComponent.vue'
import Footer from '@/components/Footer.vue'



export default {
  name: 'microneedling',
  components: {
    Hero,
    HeadlineCopy,
    SingleTextComponent,
    Footer
  }
}
</script>